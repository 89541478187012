import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Logo from "../../assets/png/supermarket-logo.png";
import { themeHome } from "../../utils/styling";

const Holder = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.text};
  height: var(--headerHeight);
  background-color: ${themeHome.colors.primary};
`;

const LogoHolder = styled.div`
  display: block;
  font-weight: 700;
  width: 100%;
`;

const LinksHolder = styled.div`
  display: flex;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  a {
    color: ${(props) => props.theme.colors.text};

    &:hover, 
    &[aria-current="page"] {
      text-decoration: underline;
    }
  }
`;

const Header = () => {
  return (
    <Holder>
      <LogoHolder>
        <Link to="/">SUPERMARKET DESIGN LTD.</Link>
      </LogoHolder>
      <LinksHolder>
        <Link to="/about">About</Link>
        <Link to="/">Home</Link>
      </LinksHolder>
    </Holder>
  );
};

export default Header;
