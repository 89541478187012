import { createGlobalStyle } from "styled-components";
import { themeHome } from "../../utils/styling";

const GlobalStyles = createGlobalStyle`

  *, *::before, *::after {
    box-sizing: border-box;
  }

  :root {
    --logoInterfaceHeight: 285px;
    --logoInterfaceHeightDesktop: 115px;
    --tickerHeight: 3rem;
    --headerHeight: 3rem;
    --white: #f6f6f6;
    --black: #000000;
    --lightBlue: #eef5ff;
    --grey: #c8c8c8;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ${(props) => props.theme.fluidType(-1)};
  }

  body {
    margin: 0;
    font-family: "Haas Grotesk Text Web", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: ${themeHome.colors.text};
    background-color: ${themeHome.colors.primary};
    scrollbar-color: transparent;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0;
    font-weight: normal;
  }

  h1, .h1 {
    ${(props) => props.theme.fluidType(8)}
    font-weight: 700;
    line-height: 1.25;
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    ${(props) => props.theme.fluidType(1)}
    line-height: 1.3;
    font-weight: 700;
  }

  .large-p, .large-p p {
    ${(props) => props.theme.fluidType(2)}
  }

  p, .p {
    ${(props) => props.theme.fluidType(0)}
    line-height: 1.3;
  }

  .small-p, .small-p p {
    ${(props) => props.theme.fluidType(-1)}
  }

  p, span, a {
    margin: 0;
    padding: 0;
  }

  ul, ol, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
  .pill,
  a.pill {
    font-weight: bold;
    border-radius: 500px;
    padding: 0.25rem 1rem 0.25rem 0.75rem;
    ${(props) => props.theme.fluidType(-5)};
    background: ${(props) => props.theme.colors.highlight};
    color: ${(props) => props.theme.colors.text};
  }
  
  svg {
    overflow: visible;
    g {
      overflow: visible;
    }
  }

  input, button, textarea, select {
    font-family: inherit;
    font-size: inherit;
  }

  input[type="text"] {
    padding: 0.5rem;
    font-size: 16px;
    @media (${(props) => props.theme.breakpoints.md}) {
      font-size: inherit;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .styled-button {
    cursor: pointer;
    color: ${({ theme }) => theme?.colorThemes?.search?.primary};
    background-color: ${({ theme }) => theme?.colorThemes?.search?.tertiary};

    padding: 0.25rem 0.75rem;
    margin: 0;
    border-radius: 2px;
    font-weight: bold;
    line-height: 1.6;

    text-align: center;
    text-transform: capitalize;
  }

  label, .label {
    ${(props) => props.theme.fluidType(-1)};
    line-height: 1.2;
    font-weight: 700;
    margin: 0 0 0.5rem 0;
    display: block;
    color: ${themeHome.colors.text};
  }

  fieldset {
    border: none;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    //background-color: rgba(255, 255, 255, 0.05);
  }

  input[type=range] {
    height: 12px;
    -webkit-appearance: none;
    margin: 0;
    width: 100%;
    background-color: transparent;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: ${themeHome.colors.text};
    border: none;
  }

  input[type=range]::-webkit-slider-thumb {
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: ${themeHome.colors.text};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: ${themeHome.colors.text};
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: ${themeHome.colors.text};
    border: none;
  }

  input[type=range]::-moz-range-thumb {
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: ${themeHome.colors.text};
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: ${(props) => props.theme.colors.highlight};
    border: 1px solid ${themeHome.colors.text};
    border-radius: 10px;
  }

  input[type=range]::-ms-fill-upper {
    background: ${(props) => props.theme.colors.highlight};
    border: 1px solid ${themeHome.colors.text};
    border-radius: 10px;
  }

  input[type=range]::-ms-thumb {
    margin-top: 1px;
    border: 1px solid ${themeHome.colors.text};
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.highlight};
    cursor: pointer;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: ${themeHome.colors.text};
  }

  input[type=range]:focus::-ms-fill-upper {
    background: ${themeHome.colors.text};
  }

  .body-copy {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 700;
      margin: 1rem 0;
    }
    a {
      text-decoration: underline;
    }

    p {
      margin: 1rem 0;
      ${(props) => props.theme.fluidType(1)}
    }
    ul {
      padding-left: 1rem;
      li {
        list-style: disc;
        margin: 0.5rem 0;
        ${(props) => props.theme.fluidType(1)}
      }
    }

    video {
      object-fit: contain;
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
    }
    > :first-child {
      margin-top: 0;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
`;

export default GlobalStyles;
