import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import styled, { ThemeProvider } from "styled-components";
import "../utils/fontface.css";

import useBoundStore from "../stores/useBoundStore";

import GlobalStyles from "../components/atoms/GlobalStyles";
import OpeningOverlay from "../components/atoms/OpeningOverlay";

import { colorSchemeThemes } from "../utils/styling";
import TickerTape from "../components/atoms/TickerTape";
import { getPageFromURL } from "../utils/helpers";
import Header from "../components/atoms/Header";

const Holder = styled.main`
  color: ${(props) => props.theme.colors.text};
`;

const Index = ({ children }) => {
  const location = useLocation();
  const { colorScheme, setColorScheme } = useBoundStore();

  useEffect(() => {
    if (location.pathname === "/") {
      setColorScheme("home");
    } else {
      setColorScheme(getPageFromURL(location.pathname));
    }
  }, [location, colorScheme, setColorScheme]);

  return (
    <ThemeProvider theme={colorSchemeThemes[colorScheme]}>
      <>
        <GlobalStyles />
        <Holder>
          <Header />
          <OpeningOverlay />
          {children}
          <TickerTape />
        </Holder>
      </>
    </ThemeProvider>
  );
};

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
