import React from "react";
import styled, { keyframes } from "styled-components";
import { themeHome } from "../../utils/styling";
import TypePill from "./TypePill";
import { Link } from "gatsby";

// See here: https://codepen.io/lewismcarey/pen/GJZVoG for details of pure CSS ticker tape implementation

const Holder = styled.div`
  position: fixed;
  z-index: 20;
  bottom: 0;
  width: 100%;
  background: var(--white);
  color: var(--black);
  height: var(--tickerHeight);
  border-top: 1px solid;
`;

const tickerAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const Seperator = styled.div`
  margin: 0 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  background: ${themeHome.colors.text};
  border-radius: 50%;
`;

const Ticker = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: var(--tickerHeight);

  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${tickerAnimation};
  animation-duration: 30s;
  animation-play-state: running;

  &:hover {
    animation-play-state: paused; /* Pausing animation on hover */
  }
  > p {
    ${(props) => props.theme.fluidType(-1)}
    text-transform: uppercase;
    font-weight: 700;

    a {
      text-decoration: none;
    }

    @media (${({ theme }) => theme.breakpoints.md}) {
      a {
        text-decoration: underline;
      }
    }
  }
  img {
    width: auto;
    height: 2.2rem;
    margin: 0 0.5rem;
  }
  > svg {
    width: auto;
    height: 2.5rem;
    margin: 0 0.8rem;
  }

  .type-pill {
    margin: 0 0.5rem;
    align-self: center;
    translate: 0 -1px;
  }
  a:last-child {
    .type-pill {
      margin-right: 0;
    }
  }
`;

const TickerTapeContent = () => {
  return (
    <React.Fragment>
      <p>
        We have recently launched a{" "}
        <Link to="/project/amp">
          <TypePill
            type="project"
            showIcon={false}
            text="New Immersive Website"
            inline
          />
        </Link>{" "}
        for{" "}
        <Link to="/client/amp">
          <TypePill type="client" showIcon={false} text="AMP" inline />
        </Link>{" "}
        in collaboration with our partner{" "}
        <Link to="/partner/figure">
          <TypePill type="partner" showIcon={false} text="Figure" inline />
        </Link>{" "}
        with some wonderful usage of{" "}
        <Link to="/tech/react-three-fiber">
          <TypePill
            type="tech"
            showIcon={false}
            text="React Three Fiber"
            inline
          />
        </Link>
      </p>
      <Seperator />
    </React.Fragment>
  );
};

const TickerTape = () => {
  return (
    <Holder>
      <Ticker>
        <TickerTapeContent />
        <TickerTapeContent />
      </Ticker>
    </Holder>
  );
};

export default TickerTape;
